import { useEffect, useState } from "react";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { Bar } from "react-chartjs-2";
import { alpha, Box, CircularProgress, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import AIOSelectComponent from "@components/input/Select.component";
import MultiSelectComponent from "@components/input/MultiSelect.component";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { globalMonthsShort } from "@utils/dayjs.utils";
import { yearsSelectItems } from "@store/boosts";
import { useSnackbar } from "notistack";
import { finalize, Observable } from "rxjs";
import { AdApplicationsStats } from "@store/ads/details";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DataColors = [Colors.chart.redDataSet, Colors.chart.orangeDataSet];

interface ClientApplicationsChartProps {
  getChartData?: (year: number) => Observable<AdApplicationsStats>;
  subtitle?: string;
  title: string;
}

const ClientApplicationsChart = (props: ClientApplicationsChartProps) => {
  const { getChartData, title, subtitle } = props;

  const theme = useTheme();
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [data, setData] = useState<AdApplicationsStats>({});

  useEffect(() => {
    if (!!getChartData) {
      setSelectedSources([]);
      setLoading(true);
      getChartData(selectedYear)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: setData,
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }
  }, [enqueueSnackbar, getChartData, selectedYear]);

  const optionsChart: any = {
    tension: 0.4,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: alpha(Colors.chart.chartBorder, 0.3),
          borderDash: [10],
          drawBorder: false,
          drawTicks: false,
        },
        ticks: {
          color: Colors.chart.chartText,
          font: {
            size: 11,
            fontWeight: 300,
          },
          backdropPadding: 0,
          padding: 10,
          count: 4,
          precision: false,
        },
      },
      x: {
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: true,
        },
        ticks: {
          color: Colors.chart.chartText,
          font: {
            size: 11,
            fontWeight: 300,
          },
          backdropPadding: 0,
          padding: 10,
        },
      },
    },
    plugins: {
      datalabels: { display: false },
      legend: { display: false },
      title: { display: false },
      tooltip: {
        backgroundColor: Colors.marine,
        displayColors: false,
        cornerRadius: 10,
        padding: 10,
        xAlign: "center",
        yAlign: "bottom",
        caretSize: 6,
        caretPadding: 10,
        titleColor: Colors.white,
        titleFont: {
          weight: "300",
        },
        bodyColor: Colors.white,
        bodyFont: {
          size: 11,
          weight: "500",
        },
        bodyAlign: "left",
        callbacks: {
          label: function (context: any) {
            console.log("context.dataset.label : ", context.dataset.label);
            return `${context.dataset.label}${context.formattedValue}`;
          },
        },
      },
    },
  };

  const sources = Object.keys(data).reduce((sources, month) => {
    let monthSources = Object.keys(data[month]?.sources ?? {});
    monthSources = monthSources.filter((m) => !sources.includes(m));
    return sources.concat(monthSources);
  }, [] as string[]);

  return (
    <Stack direction="column" spacing={breakpointDownMD ? 2 : 5} position="relative">
      <Stack>
        <Stack direction={breakpointDownMD ? "column" : "row"} justifyContent="space-between" alignItems="center">
          <Typography fontSize={20} fontWeight={700} width="100%">
            {title}
          </Typography>
          <Stack direction="row" spacing={2}>
            <MultiSelectComponent
              items={sources.map((s) => ({ label: s, value: s }))}
              placeholder={t("charts.selectSources")}
              multiSelectedLabel={(count) => t("charts.sources", { count })}
              handleChange={(values: string[]) => setSelectedSources(values)}
              values={selectedSources}
            />
            <AIOSelectComponent items={yearsSelectItems} value={selectedYear} handleChange={(value) => setSelectedYear(value)} />
          </Stack>
        </Stack>
        {!!subtitle && (
          <Typography fontSize={11} fontWeight={300} color={Colors.secondaryText}>
            {subtitle}
          </Typography>
        )}
      </Stack>
      <Box height="220px">
        <Bar
          options={optionsChart}
          data={{
            labels: globalMonthsShort,
            datasets: [
              {
                data: globalMonthsShort.map((_, index) => {
                  return data[`${index + 1 < 10 ? "0" : ""}${index + 1}`]?.onlineAnnouncements ?? 0;
                }),
                label: t("charts.ads"),
                backgroundColor: DataColors[0],
              },
              {
                data: globalMonthsShort.map((_, index) => {
                  if (!data[`${index + 1 < 10 ? "0" : ""}${index + 1}`]?.sources) return 0;

                  return Object.keys(data[`${index + 1 < 10 ? "0" : ""}${index + 1}`].sources).reduce((res, key) => {
                    if (!selectedSources.length || selectedSources.includes(key))
                      return res + (data[`${index + 1 < 10 ? "0" : ""}${index + 1}`].sources[key]?.applicationNbr ?? 0);
                    return res;
                  }, 0);
                }),
                label: t("charts.applications"),
                backgroundColor: DataColors[1],
              },
            ],
          }}
        />
      </Box>
      {loading && (
        <Stack
          position="absolute"
          bgcolor={alpha(Colors.background, 0.5)}
          height="calc(100% - 20px)"
          width="100%"
          borderRadius="0 0 15px 15px"
          justifyContent="center"
          alignItems="center">
          <CircularProgress size={40} />
        </Stack>
      )}
    </Stack>
  );
};

export default ClientApplicationsChart;
